<template>
  <div class="profile-wrapper emb-card pa-4">
    <h4>{{ $t("message.profilePage.profileInfo") }}</h4>
    <div
      v-if="customer_info != null && customer_info.deleted == true"
      class="red white--text font-weight-bold pa-2"
    >
      DELETED USER
    </div>
    <div
      v-if="customer_info != null && customer_info.banned == true"
      class="red white--text font-weight-bold pa-2"
    >
      BANNED USER
    </div>    
    <div class="pt-4">
      <div
        class="proflie-field mb-4"
        v-for="(info, key) in profileData"
        :key="key"
      >
        <div v-if="info.is_credit && !is_editing_credit && isAdmin">
          <label>{{ info.key }} :</label>
          <span
            >{{ info.value }}
            <v-btn class="mx-2" small @click="updateCredit()">
              <v-icon v-if="!credit_processing">mdi-pencil</v-icon>
              <v-progress-circular v-else></v-progress-circular>
            </v-btn>
          </span>
        </div>
        <div v-else-if="info.is_credit && is_editing_credit">
          <label>{{ info.key }} :</label>
          <span>
            <div class="d-flex flex-row align-center credit_edit">
              <v-text-field type="number" v-model="credit"></v-text-field>
              <v-btn small class="mx-2 green--text" @click="confirmCredit()">
                <v-icon> mdi-check </v-icon>
              </v-btn>
            </div>
          </span>
        </div>
        <div v-else>
          <label>{{ info.key }} :</label>
          <span>{{ info.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.credit_edit {
  width: 140px;
}
</style>

<script>
import { mapGetters } from "vuex";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
export default {
  data() {
    return {
      customer_id: "",
      profileData: [],
      customer_info: null,
      // is_editing_credit: false,
      credit: 0,
      customer_info_private: null,
      is_editing_credit: false,
      credit_processing: false,
    };
  },
  computed: {
    ...mapGetters([
      "getUserInfo",
      "getUserPrivateInfo",
      "currencies",
      "isAdmin",
    ]),
  },
  watch: {
    "$store.state.userPrivateInfo": function () {
      this.updateData();
    },
    "$store.state.userInfo": function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      const db = getFirestore();
      const docRef = doc(db, "users", this.customer_id);
      getDoc(docRef).then((doc_user) => {
        if (doc_user.exists()) {
          this.customer_info = doc_user.data();
        } else {
          console.log("No such document!");
        }
        const docRef = doc(db, "users_private", this.customer_id);
        getDoc(docRef).then((doc_private) => {
          this.customer_info_private = doc_private.data();
          this.profileData = [];
          var publicUserInfo = [
            {
              key: this.$t("message.profilePage.firstName"),
              value: this.customer_info["firstname"],
            },
            {
              key: this.$t("message.profilePage.lastName"),
              value: this.customer_info["lastname"],
            },
            {
              key: this.$t("message.profilePage.dob"),
              value: this.customer_info["dob"]
                .toDate()
                .toISOString()
                .substr(0, 10),
            },
            {
              key: this.$t("message.profilePage.phone"),
              value: this.customer_info["phone"],
            },
            {
              key: this.$t("message.profilePage.email"),
              value: this.customer_info["email"],
            },
            {
              key: this.$t("message.profilePage.language"),
              value: this.$t(
                "message.language." + this.customer_info["language"]
              ),
            },
            {
              key: this.$t("message.profilePage.credit"),
              value: this.$root.$formatMoney(this.customer_info_private.credit),
              is_credit: true,
            },
          ];
          this.credit = this.customer_info_private.credit;
          this.profileData = this.profileData.concat(publicUserInfo);
        });
      });
    },
    updateCredit() {
      this.is_editing_credit = true;
    },
    confirmCredit() {
      this.is_editing_credit = false;
      if (this.credit != this.customer_info_private.credit) {
        console.log("Update credit");

        var info = {
          user_id: this.customer_id,
          content: {
            credit: parseFloat(this.credit),
            old_credit: parseFloat(this.customer_info_private.credit),
            reason: "",
          },
        };
        this.credit_processing = true;
        const updateCreditFunc = httpsCallable(getFunctions(), "updateCredit");
        updateCreditFunc(info)
          .then((result) => {
            this.credit_processing = false;
            if (result.code == "OK") {
              this.$router.go(0);
            } else {
              this.credit = info.content.old_credit;
            }
          })
          .catch((error) => {
            console.error(error);
            this.credit_processing = false;
          });
      }
    },
  },
  beforeMount() {
    this.customer_id = this.$route.params.id;

    this.updateData();
  },
};
</script>